
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { getOrdersRequest } from "@/sea/apis/OrdersService";
import { getBranchesRequest } from "@/sea/apis/BranchesService";
import { UserUtils } from "@/sea/utils/UserUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import Datatable from "@/sea/components/datatables/Datatable.vue";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "orders",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();

    const orderNumber = ref<string | null>(null);
    const branchId = ref(0);
    const name = ref<string | null>(null);
    const telephone = ref<string | null>(null);
    const email = ref<string | null>(null);
    const type = ref(0);
    const status = ref(0);
    const paymentMethod = ref(0);
    const paymentStatus = ref(0);
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);

    orderNumber.value = route.query.orderNumber
      ? `${route.query.orderNumber}`
      : null;
    branchId.value = Number(route.query.branchId) || 0;
    name.value = route.query.name ? `${route.query.name}` : null;
    telephone.value = route.query.telephone ? `${route.query.telephone}` : null;
    email.value = route.query.email ? `${route.query.email}` : null;
    type.value = Number(route.query.type) || 0;
    status.value = Number(route.query.status) || 0;
    paymentMethod.value = Number(route.query.paymentMethod) || 0;
    paymentStatus.value = Number(route.query.paymentStatus) || 0;
    pageNumber.value = Number(route.query.pageNumber) || 1;
    pageSize.value = Number(route.query.pageSize) || 10;

    const loading = ref(false);

    const generateTableHeaders = () => {
      return [
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.orders.tblHeaderOrderNumber"
          ),
          key: "orderNumber",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderBranch"),
          key: "branch",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderPrice"),
          key: "price",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderName"),
          key: "name",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderContact"),
          key: "contact",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderType"),
          key: "type",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderStatus"),
          key: "status",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderDate"),
          key: "date",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.orders.tblHeaderActions"),
          key: "actions",
        },
      ];
    };

    const tableHeaders = ref(generateTableHeaders());

    watch(locale, () => {
      tableHeaders.value = generateTableHeaders();
    });

    const {
      ordersRef,
      totalRef: ordersTotalRef,
      pageNumberRef: ordersPageNumberRef,
      pageSizeRef: ordersPageSizeRef,
      getOrders,
    } = getOrdersRequest();

    const { branchesRef, getBranches } = getBranchesRequest();

    const onBranchChange = async (event) => {
      branchId.value = Number(event.target.value) || 0;
      await getData(false);
    };

    const onTypeChange = async (event) => {
      type.value = Number(event.target.value) || 0;
      await getData(false);
    };

    const onStatusChange = async (event) => {
      status.value = Number(event.target.value) || 0;
      await getData(false);
    };

    const updateQueries = async () => {
      const newQuery = Object.assign({}, route.query);
      if (orderNumber.value) {
        newQuery.orderNumber = orderNumber.value;
      } else {
        delete newQuery.orderNumber;
      }
      if (branchId.value > 0) {
        newQuery.branchId = branchId.value.toString();
      } else {
        delete newQuery.branchId;
      }
      if (name.value) {
        newQuery.name = name.value;
      } else {
        delete newQuery.name;
      }
      if (telephone.value) {
        newQuery.telephone = telephone.value;
      } else {
        delete newQuery.telephone;
      }
      if (email.value) {
        newQuery.email = email.value;
      } else {
        delete newQuery.email;
      }
      if (type.value > 0) {
        newQuery.type = type.value.toString();
      } else {
        delete newQuery.type;
      }
      if (status.value > 0) {
        newQuery.status = status.value.toString();
      } else {
        delete newQuery.status;
      }
      if (paymentMethod.value > 0) {
        newQuery.paymentMethod = paymentMethod.value.toString();
      } else {
        delete newQuery.paymentMethod;
      }
      if (paymentStatus.value > 0) {
        newQuery.paymentStatus = paymentStatus.value.toString();
      } else {
        delete newQuery.paymentStatus;
      }
      if (pageNumber.value) newQuery.pageNumber = pageNumber.value.toString();
      if (pageSize.value) newQuery.pageSize = pageSize.value.toString();
      await router.replace({ query: newQuery });
    };

    const getData = async (isFirstTime: boolean) => {
      loading.value = true;

      await updateQueries();
      await getOrders(
        orderNumber.value,
        branchId.value > 0 ? branchId.value : null,
        name.value,
        telephone.value,
        email.value,
        type.value > 0 ? type.value : null,
        status.value > 0 ? status.value : null,
        paymentMethod.value > 0 ? paymentMethod.value : null,
        paymentStatus.value > 0 ? paymentStatus.value : null,
        sort.value,
        pageNumber.value,
        pageSize.value
      );

      if (isFirstTime) {
        await getBranches(null, null, null, "id,asc", 1, 1000);
      }

      reinitializeComponents();

      loading.value = false;
    };

    const sortOrders = async (order) => {
      if (order) {
        sort.value = `${order.columnName},${order.order}`;
        await getData(false);
      }
    };

    const updatePageSize = async (sizePerPage) => {
      pageSize.value = sizePerPage;
      await getData(false);
    };

    const updatePageNumber = async (numberOfPage) => {
      pageNumber.value = numberOfPage;
      await getData(false);
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.orders.title", [
        "sea.aside.categories.order.title",
      ]);
      await getData(true);
    });

    return {
      I18nUtils,
      UserUtils,
      DateTimeUtils,
      name,
      branchId,
      onBranchChange,
      type,
      onTypeChange,
      status,
      onStatusChange,
      tableHeaders,
      getData,
      loading,
      ordersRef,
      ordersTotalRef,
      ordersPageNumberRef,
      ordersPageSizeRef,
      sortOrders,
      updatePageSize,
      updatePageNumber,
      branchesRef,
    };
  },
});
