import { getDataRequest, getListRequest } from "@/sea/apis/BaseService";
import { OrderResponse } from "@/sea/apis/models/order/OrderResponse";
import { ref } from "vue";

export const getOrdersRequest = () => {
  const {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getList,
  } = getListRequest<OrderResponse>();
  const ordersRef = ref<Array<OrderResponse> | null | undefined>([]);

  const getOrders = async (
    orderNumber: string | null,
    branchId: number | null,
    name: string | null,
    telephone: string | null,
    email: string | null,
    type: number | null,
    status: number | null,
    paymentMethod: number | null,
    paymentStatus: number | null,
    sort: string | null,
    pageNumber: number,
    pageSize: number
  ) => {
    await getList(true, "/orders", {
      params: {
        orderNumber: orderNumber,
        branchId: branchId,
        name: name,
        telephone: telephone,
        email: email,
        type: type,
        status: status,
        paymentMethod: paymentMethod,
        paymentStatus: paymentStatus,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
    ordersRef.value = listRef.value;
  };

  return {
    responseRef,
    errorRef,
    ordersRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getOrders,
  };
};

export const getOrderRequest = () => {
  const { responseRef, errorRef, dataRef, getData } =
    getDataRequest<OrderResponse>();
  const orderRef = ref<OrderResponse | null | undefined>(null);

  const getOrder = async (orderId: number) => {
    await getData(true, `/orders/${orderId}`);
    orderRef.value = dataRef.value;
  };

  return { responseRef, errorRef, orderRef, getOrder };
};
